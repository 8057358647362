<template>
  <v-app>
    <div>
      <!-- Header Alert -->
      <b-alert
        show
        variant="light"
        class="alert alert-custom alert-white alert-shadow fade show gutter-b"
      >
        <div class="alert-icon">
          <span class="svg-icon svg-icon-lg">
            <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
          </span>
        </div>
        <div class="alert-text">Daftar <b>Peminjaman Alat dan Bahan</b></div>
        <b-button
          squared
          @click="$router.push('/tool-borrowers/add')"
          v-if="btnAccess"
          variant="primary"
          >Tambah</b-button
        >
      </b-alert>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div>
                <!-- Filter -->
                <div class="row justify-content-end mt-n3">
                  <div class="col-md-3">
                    <treeselect
                      v-model="filter.borrower_status_id"
                      :multiple="false"
                      placeholder="Saring status"
                      :options="borrower_statuses"
                      @input="filterByBorrowerStatus"
                    />
                  </div>
                  <div class="col-md-5">
                    <b-input-group>
                      <template #append>
                        <b-button
                          variant="success"
                          squared
                          size="sm"
                          @click="search"
                        >
                          Cari
                        </b-button>
                        <b-button
                          variant="danger"
                          squared
                          size="sm"
                          @click="reset"
                        >
                          Reset
                        </b-button>
                      </template>
                      <b-form-input
                        type="text"
                        v-model="filter.name"
                        placeholder="Cari Petugas/Peminjam"
                      ></b-form-input>
                    </b-input-group>
                  </div>
                </div>
                <b-table
                  hover
                  :items="items"
                  :fields="fields"
                  class="mt-3"
                  responsive
                >
                  <template #table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"
                      :key="field.key"
                      :style="{ width: field.key === 'action' ? '15%' : '' }"
                    />
                  </template>

                  <template #cell(photo)="data">
                    <div
                      class="
                        d-flex
                        justify-content-start
                        align-items-center
                        image-container
                      "
                    >
                      <div
                        class="image-input-wrapper"
                        style="position: relative"
                      >
                        <img class="image" :src="data.item.photo_url" />
                      </div>
                    </div>
                  </template>

                  <template #cell(tool_name)="data">
                    <ul>
                      <li v-for="tool in data.item.tool_id" :key="tool.id">
                        <div class="d-flex justify-content-between my-1">
                          <span class="d-block font-weight-bold">{{
                            tool.name
                          }}</span>
                          <b-badge
                            pill
                            v-if="tool.borrower_status_id == 1"
                            class="text-white mb-1"
                            style="cursor: pointer"
                            variant="warning"
                            @click="
                              showModalReturnDate(
                                data.item,
                                data.index,
                                tool.id,
                                tool.name
                              )
                            "
                          >
                            Dipinjam
                          </b-badge>
                          <b-badge
                            pill
                            v-if="tool.borrower_status_id == 2"
                            class="btn-green text-white mb-1"
                          >
                            Selesai
                          </b-badge>
                        </div>
                      </li>
                    </ul>
                    <!-- <div
                      class="d-flex justify-content-between my-1"
                      v-for="tool in data.item.tool_id"
                      :key="tool.id"
                    >
                      <span class="">{{ tool.name }}</span>
                      <b-badge
                        v-if="tool.borrower_status_id == 1"
                        class="text-white"
                        style="cursor: pointer; background-color: #8a00b7"
                      >
                        Dipinjam
                      </b-badge>
                      <b-badge
                        v-if="tool.borrower_status_id == 2"
                        class="btn-green text-white"
                        style="cursor: pointer"
                      >
                        Selesai
                      </b-badge>
                    </div> -->
                  </template>

                  <template #cell(lab_material_name)="data">
                    <li v-for="lab_material in data.item.lab_material_id" :key="lab_material.id">
                          <span class="font-weight-bold">{{
                            lab_material.name
                          }}</span>
                      </li>
                  </template>

                  <template #cell(action)="data">
                    <!-- <b-button
                      size="sm"
                      @click="data.toggleDetails"
                      class="mr-1 btn-circle"
                      variant="outline-info"
                      v-b-tooltip.hover
                      title="Daftar Alat"
                    >
                      <i
                        class="mdi mdi-flask-empty-outline px-0"
                        v-if="!data.detailsShowing"
                      ></i>
                      <i
                        class="mdi mdi-flask-empty-outline px-0"
                        v-if="data.detailsShowing"
                      ></i>
                    </b-button> -->
                    <b-button
                      size="sm"
                      class="mr-1 btn-circle btn-outline-info"
                      v-b-tooltip.hover
                      title="Detail"
                      @click="
                        $router.push({
                          path: '/tool-borrowers/detail/' + data.item.id,
                        })
                      "
                      ><i class="fas fa-eye px-0"></i
                    ></b-button>
                    <b-button
                      size="sm"
                      class="mr-1 btn-circle btn-outline-success"
                      v-b-tooltip.hover
                      title="Edit"
                      @click="
                        $router.push({
                          path: '/tool-borrowers/edit/' + data.item.id,
                        })
                      "
                      v-if="btnAccess"
                      ><i class="fas fa-edit px-0"></i
                    ></b-button>
                    <b-button
                      size="sm"
                      class="mr-1 btn-circle btn-outline-danger"
                      v-b-tooltip.hover
                      title="Hapus"
                      @click="deleteData(data.item.id)"
                      v-if="btnAccess"
                      ><i class="fas fa-trash px-0"></i
                    ></b-button>
                    <!-- <b-button
                      size="sm"
                      class="btn-circle btn-outline-green"
                      variant="btn-outline-green"
                      v-b-tooltip.hover
                      title="Pengembalian Alat"
                      @click="showModalReturnDate(data.item, data.index)"
                      v-if="
                        data.item.borrower_status_id == 1 && btnAccess == true
                      "
                      ><i class="fas fa-undo-alt px-0"></i
                    ></b-button> -->
                  </template>

                  <template #row-details="data">
                  <b-card no-body>
                    <table class="table table-borderless">
                      <tr>
                        <th>No</th>
                        <th>Alat</th>
                        <th>Status</th>
                        <th>Tanggal Kembali</th>
                        <th>Kondisi Kembali</th>
                      </tr>
                      <tr v-for="(tool, i) in data.item.tool_id" :key="tool.id">
                        <td>{{i + 1}}</td>
                        <td>{{tool.name}}</td>
                        <td>{{tool.tool_status_id == 1 ? "Dipinjam" : "Selesai"}}</td>
                        <td>{{tool.return_date != null ? tool.return_date : '-'}}</td>
                        <td>{{tool.tool_status_id == 1 ? "Baik" : tool.tool_status_id == 2 ? 'Rusak' : '-'}}</td>
                      </tr>
                    </table>

                    <b-button
                        class="card-link text-white btn-primary"
                        size="sm"
                        variant="primary"
                        @click="data.toggleDetails"
                        >Tutup</b-button
                      >
                  </b-card>
                </template>
                </b-table>

                <b-pagination
                  v-if="items.length != 0"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  @page-click="pageOnClick"
                  class="mt-4"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal Return Date -->
      <b-modal id="modal-return-date" @ok="toolReturn">
        <template #modal-title>Pengembalian Alat</template>
        <b-form-group
          id="input-group-tool"
          label="Alat:"
          label-for="input-tool"
        >
          <b-form-input
            id="input-tool"
            v-model="formReturnDate.tool_name"
            disabled
          ></b-form-input>
        </b-form-group>

        <!-- <b-form-group
          id="input-group-tool-id"
          label="Alat:"
          label-for="input-tool-id"
        >
          <treeselect
            v-model="borrowedToolSelected"
            :multiple="false"
            placeholder="Pilih Alat"
            :options="borrowedTool"
            @input="setReturnTool"
          />
          <small class="text-danger">{{ errorReturnDate.return_tool }}</small>
        </b-form-group> -->

        <b-form-group
          id="input-group-user2"
          label="Peminjam:"
          label-for="input-user2"
        >
          <b-form-input
            id="input-user2"
            v-model="formReturnDate.user_name"
            disabled
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-officer"
          label="Petugas:"
          label-for="input-officer"
        >
          <b-form-input
            id="input-officer"
            v-model="formReturnDate.officer_name"
            disabled
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-borrow-date"
          label="Tanggal Pinjam:"
          label-for="input-borrow-date"
        >
          <b-form-input
            id="input-borrow-date"
            v-model="formReturnDate.borrow_date"
            disabled
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-buy-date">
          <label for="input-buy-date">Tanggal Kembali: </label>
          <v-menu
            v-model="menuDate1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <b-form-input
                id="input-date"
                v-model="formReturnDate.return_date"
                label="Tanggal Kembali:"
                readonly
                v-bind="attrs"
                v-on="on"
              ></b-form-input>
            </template>
            <v-date-picker
              v-model="formReturnDate.return_date"
              @input="menuDate1 = false"
            ></v-date-picker>
          </v-menu>
          <small class="text-danger">{{ errorReturnDate.return_date }}</small>
        </b-form-group>

        <b-form-group
          label="Kondisi"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-radio-group
            id="radio-group-2"
            v-model="formReturnDate.tool_status_id"
            :aria-describedby="ariaDescribedby"
            name="radio-sub-component"
          >
            <b-form-radio value="1">Baik</b-form-radio>
            <b-form-radio value="2">Rusak</b-form-radio>
          </b-form-radio-group>
          <small class="text-danger">{{ errorReturnDate.tool_status_id }}</small>
        </b-form-group>
      </b-modal>
    </div>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";

export default {
  data() {
    return {
      // filter
      filter: {
        name: "",
        tool_status_id: "",
        borrower_status_id: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "borrow_date_display",
          label: "Tgl. Pinjam",
          sortable: true,
        },
        // {
        //   key: "return_date_display",
        //   label: "Tgl. Kembali",
        //   sortable: true,
        // },
        {
          key: "tool_name",
          label: "Alat",
          sortable: true,
        },
        {
          key: "lab_material_name",
          label: "Bahan",
          sortable: true,
        },
        {
          key: "user_name",
          label: "Peminjam",
          sortable: true,
        },
        {
          key: "officer_name",
          label: "Petugas",
          sortable: true,
        },
        // {
        //   key: "borrower_status_name",
        //   label: "Status",
        //   sortable: true,
        // },
        {
          key: "action",
          label: "Aksi",
          tdClass: "nowrap",
        },
      ],
      items: [],
      client_types: [],
      tool_statuses: [],
      borrower_statuses: [],
      //formReturnDate
      formReturnDate: {
        return_date: new Date().toISOString().substr(0, 10),
        user_name: "",
        officer_name: "",
        // required
        id: 0,
        tool_name: 0,
        return_tool: [],
        borrow_date: new Date().toISOString().substr(0, 10),
        borrower_status_id: "",
        borrowed_tool_id: "",
        tool_status_id: "",
      },
      errorReturnDate: {
        return_date: "",
        return_tool: "",
        tool_status_id: "",
      },
      menuDate1: false,
      itemIndex: "",
      borrowedTool: [],
      borrowedToolSelected: "",
      // access
      btnAccess: false,
    };
  },
  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      //("evt", evt);
      //("page", page);
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}&borrower_status_id=${this.filter.borrower_status_id}`;
      let response = await module.paginate(
        "api/tool-borrowers",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    async getBorrowerStatusOption() {
      let response = await module.setTreeSelect("api/borrower-statuses");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.borrower_statuses = response.data;
        this.borrower_statuses.unshift({
          label: "Saring status",
          id: "",
          isDisabled: true,
        });
      }
    },

    filterByBorrowerStatus(evt) {
      if (typeof evt == "undefined") {
        this.filter.borrower_status_id = "";
      }
      this.pagination();
    },

    search() {
      this.pagination();
    },
    reset() {
      this.filter.name = "";
      this.filter.borrower_status_id = "";
      this.pagination();
    },

    showModalReturnDate(data, index, toolId, tool_name) {
      this.formReturnDate.id = data.id;
      this.formReturnDate.tool_name = tool_name;
      this.formReturnDate.user_name = data.user_name;
      this.formReturnDate.officer_name = data.officer_name;
      this.formReturnDate.borrow_date = data.borrow_date;
      this.formReturnDate.return_tool = data.return_tool;
      this.formReturnDate.borrowed_tool_id = toolId;
      // this.formReturnDate.return_tool.push(toolId);
      this.formReturnDate.return_date = new Date().toISOString().substr(0, 10);
      console.log("formReturnDate", this.formReturnDate);
      this.$bvModal.show("modal-return-date");
      this.itemIndex = index;
      this.getBorrowedTool(data);
    },
    async getBorrowedTool(data) {
      let return_tool = [0, 0];
      if (data.return_tool.length > 0) {
        return_tool = data.return_tool;
      }
      let tool_id = [];
      for (let a = 0; a < data.tool_id.length; a++) {
        tool_id.push(data.tool_id[a].id);
      }
      let filterParams = `&purpose=borrowed_tools&tool_id=${tool_id.toString()}&return_tool=${return_tool.toString()}`;

      let response = await module.setTreeSelect(
        "api/tools",
        `?page_size=50${filterParams}`
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.borrowedTool = response.data;
        this.borrowedTool.unshift({
          label: "Pilih Alat",
          id: "",
          isDisabled: true,
        });
      }
    },
    setReturnTool(evt) {
      if (typeof evt == "undefined") {
        this.borrowedToolSelected = "";
      } else {
        this.borrowedToolSelected = evt;
      }
      // console.log("formReturnDate", this.formReturnDate);
    },
    validationCheck() {
      let status = true;
      if (this.formReturnDate.return_date < this.formReturnDate.borrow_date) {
        this.errorReturnDate.return_date =
          "Tanggal pengembalian harus lebih terkini dari tanggal kembali";
        status = false;
      } else {
        this.errorReturnDate.return_date = "";
      }
      // if (this.borrowedToolSelected == "") {
      //   this.errorReturnDate.return_tool = "Alat harus dipilih";
      //   status = false;
      // } else {
      //   this.errorReturnDate.return_tool = "";
      // }
      if (this.formReturnDate.tool_status_id == "") {
        this.errorReturnDate.tool_status_id = "Status harus dipilih";
        status = false;
      } else {
        this.errorReturnDate.tool_status_id = "";
      }

      return status;
    },
    async toolReturn(bvModalEvt) {
      bvModalEvt.preventDefault();

      if (this.validationCheck() == true) {
        this.formReturnDate.return_tool.push(this.borrowedToolSelected);
        this.formReturnDate.tool_id = JSON.stringify(
          this.formReturnDate.tool_id
        );
        // this.formReturnDate.lab_material_id = JSON.stringify(
        //   this.formReturnDate.lab_material_id
        // );
        // this.formReturnDate.return_tool = JSON.stringify(
        //   this.formReturnDate.return_tool
        // );

        console.log("formReturnDate", this.formReturnDate);

        let formData = new FormData();
        for (const [key, value] of Object.entries(this.formReturnDate)) {
          formData.append(key, value);
        }

        let response = await module.submit(
          formData,
          `api/tool-borrowers/${this.formReturnDate.id}/tool-return`
        );
        if (response.state == "error") {
          Swal.fire({
            title: "Gagal",
            text: "Gagal mengembalikan alat",
            icon: "error",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$bvModal.hide("modal-return-date");
        } else {
          // Success
          Swal.fire({
            title: response.success.title,
            text: response.success.message,
            icon: "success",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$bvModal.hide("modal-return-date");
          this.pagination();
        }
      } else {
        // this.errorReturnDate.return_date =
        //   "Tanggal pengembalian harus lebih besar dari tanggal kembali";
        Swal.fire({
          title: "Gagal",
          text: "Harap periksa kembali form",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("api/tool-borrowers/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1111") {
          this.btnAccess = true;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Peminjaman Alat dan Bahan", route: "/tool-borrowers" },
    ]);
    this.pagination();
    this.getBorrowerStatusOption();
    this.checkAccess();
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}

.image-input-wrapper {
  width: 50px !important;
  height: 50px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sr-only {
  width: 0px !important;
}
</style>
